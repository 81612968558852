import React from "react"
import {
  GridBox,
  Standalone,
  Text,
  Title,
} from "@thedgbrt/logology-ui-components"
import SEO from "../../components/seo"

const PrivacyPage = () => (
  <>
    <SEO title="Privacy policy" />
    <GridBox type={5} colSpans={[[3, 8], [3, 8], [3, 9]]} withBgContainer>
      <Title standalone>Privacy policy</Title>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Text italic>
          This privacy statement describes how Logology (“we”, “us”) collects
          and uses the Personal Information you willingly provide on our Web
          site: logology.co. It also describes the choices available to you
          regarding our use of your Personal Information and how you can access
          and update this information. Personal Information is information about
          you, that is not otherwise available to the public, that you willingly
          provide to Logology in order to use or pay for our services.
        </Text>
        <Text italic>
          If you have questions or concerns regarding this policy, you should
          first contact us at{" "}
          <a href="mailto:team@logology.co">team@logology.co</a>.
        </Text>
      </Standalone>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Collection and Use of Personal Information</Title>{" "}
        <Text italic>
          In order to facilitate the operation of our site and manage your
          account, we collect Personal Information from you. This information
          may include but is not limited to, your name, email address, credit
          card or debit card number, your zip code, your address and phone
          number.
        </Text>
        <Text italic>
          Depending on the documents you create with our service we may collect
          Personal Information including, but not limited to, names of people
          you have personal or business relationships with, items you own or
          wish to transfer ownership of, financial matters, and personal
          accounts or account numbers with banks, financial institutions,
          merchants and vendors.
        </Text>
        <Text italic>
          We use this information to:
          <ul>
            <li>Fulfill your order </li>
            <li>Send you an order confirmation </li>
            <li>
              Assess the needs of your business to determine suitable products{" "}
            </li>
            <li>Respond to customer service requests </li>
            <li>Send you a newsletter </li>
            <li>Send you marketing communications </li>
            <li>Respond to your questions and concerns </li>
            <li>Improve our Web site and marketing efforts </li>
            <li>Conduct research and analysis </li>
            <li>Electronic and Web Browsing Information We Gather</li>
          </ul>
        </Text>
        <Text italic>
          As is true of most Web sites, we automatically gather information
          about your computer such as your IP address, browser type, browser
          settings, navigation through our site(s), referring/exit pages, and
          operating system. This information does not include Personal
          Information. We may use third party tools and analytical services to
          gather and analyze this information.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Choice/Opt-Out</Title>
        <Text italic>
          You may choose to stop receiving our newsletter or marketing emails by
          following the unsubscribe instructions included in these emails or you
          can contact us at{" "}
          <a href="mailto:team@logology.co">team@logology.co</a>.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Information Sharing</Title>

        <Text italic>
          We will share your Personal Information with third parties only in the
          ways that are described in this privacy statement. We do not sell your
          Personal Information to third parties.
        </Text>

        <Text italic>
          We may provide your Personal Information to companies that provide
          services to help us with our business activities such as credit/debit
          card processing, shipping your order or offering customer service.
          These companies are authorized to use your Personal Information only
          as necessary to provide these services to us.
        </Text>

        <Text italic>
          We may also disclose your Personal Information as required by law such
          as to comply with a subpoena or similar legal process when we believe
          in good faith that disclosure is necessary to protect our rights,
          protect your safety or the safety of others, investigate fraud, or
          respond to a government request.
        </Text>

        <Text italic>
          If Logology is involved in a merger, acquisition, or sale of all or a
          portion of its assets, you will be notified via email and/or a
          prominent notice on our Web site of any change in ownership or uses of
          your Personal Information, as well as any choices you may have
          regarding your Personal Information, to any other third party with
          your prior consent to do so.
        </Text>

        <Text italic>
          To protect against fraud and misuse of our Website and Services, we
          actively analyze purchases, attempted purchases and use of the Website
          and Services to help us identify and block individuals suspected of
          misuse and/or fraudulent activity. We may use that information, in
          conjunction with our security partners and/or law enforcement to
          protect Logology’s business and systems, your Personal Information and
          assist those we reasonably believe may have been victimized by such
          fraudulent use of the Website and Services. We may share data
          (including, but not limited to, suspect payment information, email
          addresses, IP addresses, etc.) with our payment processors, and law
          enforcement, in an effort to reduce fraud. You consent to the
          provision of this information (including keyword search data) for such
          investigative purposes.
        </Text>
      </Standalone>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Third Party E-Commerce Solutions Provider</Title>
        <Text italic>
          Our shopping cart interacts with established PCI-DSS compliant
          third-party credit processing vendor(s) via SSL links. They host and
          collect your billing information for the purpose of processing your
          order and subscriptions. We use an established third-party vendor via
          SSL links to manage processing of refunds and updates of credit card
          and debit card numbers and expiration dates. These companies do not
          use this information for any other purpose. We do not store credit
          card and debit card numbers on our servers or systems.
        </Text>
      </Standalone>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Cookies and Other Tracking Technologies</Title>
        <Text italic>
          We may use cookies, for example, to keep track of your preferences and
          profile information. Cookies are also used to collect general usage
          and volume statistical information that does not include Personal
          Information.
        </Text>
      </Standalone>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Links to Other Web Sites</Title>
        <Text italic>
          Our Site may include links to other Web sites whose privacy practices
          may differ from those of Logology. If you submit Personal Information
          to any of those sites, your information is governed by their privacy
          policies. We encourage you to carefully read the privacy policies of
          any Web site you visit.
        </Text>
      </Standalone>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Security</Title>
        <Text italic>
          When we collect Personal Information directly from you, we follow
          generally accepted industry standards to protect the Personal
          Information submitted to us, both during transmission and once we
          receive it. No method of transmission over the Internet, or method of
          electronic storage, is 100% secure, however. Therefore we cannot
          guarantee its absolute security. If you have any questions about
          security on our Web site, you can contact us at{" "}
          <a href="mailto:team@logology.co">team@logology.co</a>
        </Text>
        <Text italic>
          In order to make a purchase from us, you must use our third party
          credit processing vendor to finalize and pay for your order. Its
          privacy statement and security practices will also apply to your
          personal Information. We encourage you to read that privacy statement
          before providing your information.
        </Text>
      </Standalone>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Additional Policy Information</Title>
        <Text italic>
          To review, correct and update your personal information to ensure it
          is accurate, contact us at{" "}
          <a href="mailto:support@logology.co">support@logology.co</a>.
        </Text>
      </Standalone>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Notification of Privacy Statement Changes</Title>
        <Text italic>
          We may update this privacy statement to reflect changes to our privacy
          practices. If we make any material changes we will notify you by email
          (sent to the e-mail address specified in your account) or by means of
          a notice on this Site prior to the change becoming effective. We
          encourage you to periodically review this page for the latest
          information on our privacy practices.
        </Text>
      </Standalone>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Contact Information</Title>
        <Text italic>
          You can contact us about this privacy policy by writing or emailing us
          at: Logology SAS, 11 place Simon Volant, 59000 Lille, France. Email:{" "}
          <a href="mailto:team@logology.co">team@logology.co</a>.
        </Text>
      </Standalone>
    </GridBox>
  </>
)

export default PrivacyPage
